import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import SlpLineChart from "./SlpLineChart";

const AccountOverviewChart = () => { 
    const data = useStaticQuery(graphql`
        query {
            allContentfulAxieFarmEntryClaimSlp(
                sort: { fields: claimedDate, order: ASC }
            ) {
                edges {
                    node {
                        id
                        accountName
                        earnedSlp
                        claimedDate
                    }
                }
            }
            allContentfulAxieFarmAccounts(
                sort: { fields: accountName, order: ASC }
            ) {
                edges {
                    node {
                        id
                        accountName
                        createdDate
                    }
                }
            }
        }
    `);

    // adds a number of days to a specified date then returns the new date.
    function AddDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    function CalculateDaysBetween(date1, date2) {
        if (new Date(date1).valueOf() === new Date(date2).valueOf()) {
            // return 0 days difference
            return 0;
        }

        var difference = new Date(date2).getTime() - new Date(date1).getTime();

        return Math.ceil(difference / (1000 * 3600 * 24));
    }

        var accounts = data.allContentfulAxieFarmAccounts.edges.map(x => ({
            accountName: x.node.accountName,
            createdDate: x.node.createdDate,
        }));

        var allMonthlyData = [];

        accounts.forEach(account => {
            var contentEntries = data.allContentfulAxieFarmEntryClaimSlp.edges
                .filter(
                    slpEntry =>
                        slpEntry.node.accountName === account.accountName
                )
                .map(slpEntry => ({
                    earnedSlp: slpEntry.node.earnedSlp,
                    claimedDate: slpEntry.node.claimedDate,
                    accountName: slpEntry.node.accountName,
                }));

            var monthlyData = []; // monthly data points.
            var chartStartDate = new Date(2021, 0, 1); //chart start date //TODO: find the earliest account.createdDate;
            var previousClaimDate = chartStartDate; //chart start date //TODO: find the earliest account.createdDate;
            var isFirstEntry = true;
            var tempSlpCount = 0;
            

            // loop through each content entry.
            for (let i = 0; i < contentEntries.length; i++) {
                // let dataPointStartDate = (new Date (previousClaimDate).getDate() < new Date(account.createdDate).getDate()) ? previousClaimDate : account.createdDate;
                // work out the average slp per day.
                const claimedDate = contentEntries[i].claimedDate;
                const dataPointStartDate = account.createdDate;
                
                if (isFirstEntry) {
                    const daysBetweenStartandCreated = CalculateDaysBetween(
                        chartStartDate,
                        account.createdDate
                    ); 
                    for (let i = 0; i < daysBetweenStartandCreated; i++) {
                        const date = AddDays(dataPointStartDate, i + 1); // increment the date

                        if (date.getDate() === 1 || date.getDate() === 16) {
                            monthlyData.push({
                                slpGained: 0,
                                date: AddDays(date, -1).toLocaleDateString("en-uk"),
                            });
                        }
                    }
                }

                if (isFirstEntry) {
                    previousClaimDate = account.createdDate;
                }
                
                
                const daysBetween = CalculateDaysBetween(
                    previousClaimDate,//previousClaimDate,//account.createdDate,//dataPointStartDate,
                    claimedDate
                );

                const averageSlpPerDay =
                    (daysBetween === 0)
                        ? 0
                        : contentEntries[i].earnedSlp / daysBetween;

                // split the {customeDailyData} into monthly data points.
                for (let i = 0; i < daysBetween; i++) {
                    const date = AddDays(previousClaimDate, i + 1); // increment the date
                    // if date is on the 1st, push the data before incrementing.
                    if (date.getDate() === 1 || date.getDate() === 16) {
                        monthlyData.push({
                            slpGained: tempSlpCount,
                            date: AddDays(date, -1).toLocaleDateString("en-uk"),
                        });

                        tempSlpCount = 0; // reset the slp count.
                    }

                    tempSlpCount += averageSlpPerDay;
                }
                previousClaimDate = claimedDate;
                isFirstEntry = false;
            }
            allMonthlyData.push({
                accountName: account.accountName,
                data: monthlyData,
            });
        });

        // dataset
        var datasets = [];

        allMonthlyData.forEach(x => {
            datasets.push({
                data: x.data.map(data => (data.slpGained)),
                label: x.accountName,
                borderColor: "pink",//RandomRGBColour(0.9),// // TODO: add contentful config for this
                backgroundColor: "rgba(255, 0, 0, 0.5)",//RandomRGBColour(0.5)// // TODO: add contentful config for this
            });
        });

        // the y axis labels will start and end depending on the longest dataset
        var longestData = allMonthlyData.sort(function (a, b) {
            return b.length - a.length; // Descending order on length
        })[0];

        return (
            <div key="account-overview-chart-container">
                <SlpLineChart 
                    title="Account Overview Chart"
                    labels= {longestData.data.map(data => data.date)}
                    datasets = {datasets}
                /> 
            </div>
        );
    


}; 

export default AccountOverviewChart;