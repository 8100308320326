// SECRET PAGE
import React from "react";

import SecretLayout from "../components/secret-layout/secret-layout";
import ClaimDateWidget from "../components/axie-farm/ClaimDateWidget";
import AccountOverviewChart from "../components/axie-farm/AccountOverviewChart";
import FarmOverallChart from "../components/axie-farm/FarmOverallChart";
import dansAquaImage from "../images/dans-aqua.png";

const AxieFarm = () => {
    return (
        <SecretLayout
            title="Axie Farm"
            navIndex={1}
            headerImage={dansAquaImage}
        >
            <ClaimDateWidget />
            <FarmOverallChart />
            <AccountOverviewChart />
        </SecretLayout>
    );
};

export default AxieFarm;
