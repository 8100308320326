import React from "react";
import * as styles from "./ClaimDateWidget.module.scss";
import { graphql, useStaticQuery } from "gatsby";
import { Card, CardContent, Typography, makeStyles, Grid } from '@material-ui/core';

import cx from "classnames"; // cx is the standard practice to use when using classnames which allows multiple styles

const useStyles = makeStyles({
    contentTitle: {
      fontWeight: "bold"
    }
  });

const ClaimDateWidget = () => {
    const data = useStaticQuery(graphql`
    query {
        allContentfulAxieFarmEntryClaimSlp(
            sort: { fields: claimedDate, order: DESC }
        ) {
            edges {
                node {
                    id
                    accountName
                    earnedSlp
                    claimedDate
                }
            }
        }
        allContentfulAxieFarmAccounts(
            sort: { fields: accountName, order: DESC }
        ) {
            edges {
                node {
                    id
                    accountName
                    createdDate
                }
            }
        }
    }
    `);

    var accounts = data.allContentfulAxieFarmAccounts.edges.map(x => ({
        accountName: x.node.accountName,
        createdDate: x.node.createdDate,
    }));

    const classes = useStyles();

    let AccountWidget = (accountName, createdDate) => {
        // ordered in graphql query descending
        var contentEntries = data.allContentfulAxieFarmEntryClaimSlp.edges
                .filter(
                    slpEntry =>
                        slpEntry.node.accountName === accountName
                )
                .map(slpEntry => slpEntry.node.claimedDate);

                let mostRecentClaimDate = new Date(contentEntries[0] !== undefined ? contentEntries[0] : new Date(createdDate)); // this is a date/time

                // if it's a new account and has never claimed before use the {createdDate}.
                if (mostRecentClaimDate === 'Invalid Date')
                    mostRecentClaimDate = new Date(createdDate);

                let claimDate = new Date(mostRecentClaimDate.getFullYear(),mostRecentClaimDate.getMonth(),mostRecentClaimDate.getDate() + 14, mostRecentClaimDate.getHours(), mostRecentClaimDate.getMinutes());

                return (
                    <Grid key={"account-claim-widget-"+ accountName} item component={Card} xs={6} md={2} 
                        className={
                            cx(styles.card, 
                                (DateIsLessThanToday(claimDate)) ? styles.readyToClaimStatus : styles.defaultStatus
                            )
                        }
                        onClick={() => {
                            window.location.href = `/axie-farm/${accountName
                                .toLowerCase()
                                .replace(/\s+/g, "")}` // remove spaces`
                            } 
                        }
                        >
                        <CardContent className={styles.contentContainer}>
                            <div className={styles.cardContentTitle}>
                                    <Typography className={classes.contentTitle} varient="h3" color="primary" gutterBottom>{accountName}</Typography>
                            </div>
                            <div>
                                <Typography varient="secondary"> {DateIsLessThanToday(claimDate) ? "" : "Next claim:"}</Typography>    
                            </div>  
                            <div>
                                <Typography varient="textSecondary"> {DateIsLessThanToday(claimDate) ? "Ready to Claim" : claimDate.toDateString()}</Typography>    
                            </div>  
                        </CardContent>
                    </Grid>           
                )
    };

    let DateIsLessThanToday = (date) => {
        if (date < new Date()) {
            return true;
        }
        
        return false;
    }

    return (
        <div>
            <div className={styles.container}>   
                <Grid container spacing={1} padding={3} justifyContent="center">
                        { 
                accounts.map(x => {
                    return AccountWidget(x.accountName, x.createdDate);
                })
                }
        
                </Grid>        
            </div>  
        </div>
    )
};

export default ClaimDateWidget;