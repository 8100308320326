import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import SlpLineChart from "./SlpLineChart";

const FarmOverallChart = () => { 
    const data = useStaticQuery(graphql`
        query {
            allContentfulAxieFarmEntryClaimSlp(
                sort: { fields: claimedDate, order: ASC }
            ) {
                edges {
                    node {
                        id
                        accountName
                        earnedSlp
                        claimedDate
                    }
                }
            }
            allContentfulAxieFarmAccounts(
                sort: { fields: accountName, order: ASC }
            ) {
                edges {
                    node {
                        id
                        accountName
                        createdDate
                    }
                }
            }
        }
    `);

    var accounts = data.allContentfulAxieFarmAccounts.edges.map(x => ({
        accountName: x.node.accountName,
        createdDate: x.node.createdDate,
    }));

    // adds a number of days to a specified date then returns the new date.
    function AddDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    function CalculateDaysBetween(date1, date2) {
        if (new Date(date1).valueOf() === new Date(date2).valueOf()) {
            // return 0 days difference
            return 0;
        }

        var difference = new Date(date2).getTime() - new Date(date1).getTime();

        return Math.ceil(difference / (1000 * 3600 * 24));
    }

    // Get all monthly data for each account
    var allMonthlyData = [];
        
    // Get the grand totals for all the accounts per month
    //  - month: slpEarned
    var grandTotalsDict = {};
           
    accounts.forEach(account => {
        var contentEntries = data.allContentfulAxieFarmEntryClaimSlp.edges
            .filter(
                slpEntry =>
                    slpEntry.node.accountName === account.accountName
            )
            .map(slpEntry => ({
                earnedSlp: slpEntry.node.earnedSlp,
                claimedDate: slpEntry.node.claimedDate,
                accountName: slpEntry.node.accountName,
            }));

        var monthlyData = []; // monthly data points.
        var chartStartDate = new Date(2021, 0, 1)
        var previousClaimDate = chartStartDate; //chart start date //TODO: find the earliest account.createdDate;
        var tempDataPointSlpCount = 0;

        // loop through each content entry.
        for (let i = 0; i < contentEntries.length; i++) {
            // work out the average slp per day.
            const claimedDate = contentEntries[i].claimedDate;
            const daysBetween = CalculateDaysBetween(
                previousClaimDate,
                claimedDate
            );
            const averageSlpPerDay =
                (daysBetween  === 0 || previousClaimDate <= account.createdDate)
                    ? 0
                    : contentEntries[i].earnedSlp / daysBetween;

            // split the {customeDailyData} into monthly data points.
            for (let i = 0; i < daysBetween; i++) {
                const date = AddDays(previousClaimDate, i + 1); // increment the date

                // if date is on the 1st, push the data before incrementing.
                if (date.getDate() === 1) {
                    // decrement the date i.e. end of the previous month
                    const dataPointDate = AddDays(date, -1).toLocaleDateString("en-uk");
        
                    monthlyData.push({
                        slpGained: tempDataPointSlpCount,
                        date: dataPointDate,
                    });

                    // add the earned slp to the grand totals dict where the key is equal to the date (end of month)
                    if (dataPointDate in grandTotalsDict)
                    {
                        grandTotalsDict[dataPointDate] += tempDataPointSlpCount; 
                    } else {
                        // initialise 'key'
                        grandTotalsDict[dataPointDate] = tempDataPointSlpCount;
                    }
                                
                    tempDataPointSlpCount = 0; // reset the slp count.
                }

                tempDataPointSlpCount += averageSlpPerDay;
            }
            previousClaimDate = claimedDate;
        }
        allMonthlyData.push({
            accountName: account.accountName,
            data: monthlyData,
        });
    });

    // dataset
    var datasets = [];

    datasets.push({
        data: Object.values(grandTotalsDict),
        label: "Total Earnings",
        borderColor: "pink", // TODO: add contentful config for this
        backgroundColor: "rgba(255, 0, 0, 0.5)", // TODO: add contentful config for this
        fill: true, //fill space below chart
    });

    return (
        <div key="farm-overall-chart">
            <SlpLineChart title="Overall Earnings Report" labels={Object.keys(grandTotalsDict)} datasets={datasets}></SlpLineChart>
        </div>
    );
    


}; 

export default FarmOverallChart;